import React, { useState } from 'react'
import logo from '../images/event_4_logo.png'
import { Link } from 'react-router-dom'
function Header() {
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
	};
	return (
		<header className="header-area">
			<div className="container">
				<div className="header_inner">
					<div className="logo">
						<Link to="/">
							<img src={logo} alt="logo" />
						</Link>
					</div>
					<div className="nav_items">
						<ul>
							<li><Link to="/">Home</Link></li>
							<li><Link to="/#games">games</Link></li>
							<li><Link to="/#events">events</Link></li>
							<li><Link to="/#contact ">Contact Us</Link></li>
						</ul>
					</div>
					<div className="menu-btn" onClick={toggleMenu}>
						<span></span>
						<span></span>
						<span></span>
					</div>
				</div>
			</div>
			<div className={`sidebar-menu ${menuOpen&&'active'}`} >
				<div className="menu-header">
					<div className="mobil-brand">
						<img className="img-fluid" src={logo} alt="" />
					</div>
					<div className="close-btn"  onClick={() => setMenuOpen(false)}>
						<span className="close-icon"></span>
					</div>
				</div>
				<div className="menu-wrap">
					<div className="menu-item"><a href="/">Home</a></div>
					<div className="menu-item"><a href="/#games">games</a></div>
					<div className="menu-item"><a href="/#events">events</a></div>
					<div className="menu-item"><a href="/#contact ">Contact Us</a></div>
				</div>
			</div>
		</header>
	)
}

export default Header
