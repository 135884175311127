import React from 'react'
import hero_1 from '../images/hero_1.jpeg'
import hero_2 from '../images/hero_2.jpeg'
import hero_3 from '../images/hero_3.jpeg'
import about_us from '../images/about_us.jpg'
import about_us2 from '../images/about_us.jpeg'
import event_1 from '../images/event_1.webp'
import event_2 from '../images/e_2.webp'
import event_3 from '../images/e_3.webp'
import r1 from '../images/r_1.webp'
import r2 from '../images/r_2.webp'
import r3 from '../images/r_3.webp'
import star from '../images/star.svg'

function Main() {
  return (
    <div>


	<section className="hero_area" id='events'>
		<div className="container">
			<div className="hero_inner">
				<div className="hero_left">
					<h1>Discover the Casino Experience at events</h1>
					<p>Discover the Casino Experience at Events by bringing the excitement of classic casino games like poker, blackjack, roulette, and slots to your next gathering. Perfect for corporate events, fundraisers, or private parties, these immersive setups feature professional dealers, themed decor, and exciting prizes. Guests can enjoy the thrill of gaming in a fun, risk-free environment, creating memorable experiences with friends and colleagues.</p>
					<div className="main_btn">
						<a href="#contact">
							learn more
						</a>
					</div>
				</div>
				<div className="hero_right">
					<div className="hero_right_img">
						<img src={hero_1} alt=""/>
						<img src={hero_2} alt=""/>
					</div>
					<div className="hero_right_img_3">
						<img src={hero_3} alt=""/>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="about_us_area">
		<div className="container">
			<div className="about_inner">
				<div className="about_us_right">
					<img src={about_us} alt=""/>
				</div>
				<div className="about_us_left">
					<h2>Unforgettable Games & Events Tailored for You</h2>
					<p>Experience the excitement of Baccarat, Poker, Roulette, and more! Whether it's private events, birthday parties, or thrilling horse racing nights, we bring the fun to you with our expertly organized casino-style entertainment.</p>
				
				</div>
			</div>
		</div>
	</section>

	<section className="about_us_area">
		<div className="container">
			<div className="about_inner">
				<div className="about_us_left">
					<h2>Premium Casino Entertainment for Unmatched Events</h2>
					<p>Step into a world of unparalleled excitement with our expertly tailored casino-themed experiences. We specialize in bringing the authentic thrill of the casino right to your doorstep, offering a wide variety of games and entertainment options that cater to all kinds of events. Whether you're planning an intimate private event, hosting a grand birthday party, or seeking the heart-racing excitement of a horse racing night, our team is dedicated to creating acustomized experience that will leave your guests talking long after the night is over.</p>
				
				</div>
				<div className="about_us_right">
					<img src={about_us2} alt=""/>
				</div>
			</div>
		</div>
	</section>

	<section className="events_area" id="games">
		<div className="container">
			<div className="events_title">
				<h2>we make the entire process stress-free and fun for you and your guests.</h2>
				<p>
					"Immerse Yourself in the Thrill of Casino Games" Join us for an unforgettable evening featuring timeless favorites like Baccarat, Poker, and Roulette. Our expertly curated casino-themed events are ideal for corporate functions, private parties, and special occasions.
				</p>
			</div>
			<div className="events_cnt">
				<div className="events_item">
					<div className="event_img">
						<img src={event_1} alt=""/>
					</div>
					<div className="event_text">
						<h3>Baccarat: The Game of Sophistication</h3>
						<div className="main_btn">
							<a href="#contact">
								book now
							</a>
						</div>
					</div>
				</div>
				<div className="events_item">
					<div className="event_img">
						<img src={event_2} alt=""/>
					</div>
					<div className="event_text">
						<h3>Poker: A Battle of Wits and Strategy</h3>
						<div className="main_btn">
							<a href="#contact">
								book now
							</a>
						</div>
					</div>
				</div>
				<div className="events_item">
					<div className="event_img">
						<img src={event_3} alt=""/>
					</div>
					<div className="event_text">
						<h3>Roulette: Spin the Wheel of Fortune</h3>
						<div className="main_btn">
							<a href="#contact">
								book now
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>

	<section className="reviwe_area">
		<div className="container">
			<div className="events_title">
				<h2>What Our Guests Are Saying</h2>
				<p>From memorable birthday celebrations to exciting themed events, our guests rave about their incredible experiences. We design one-of-a-kind, in-person events customized just for you—let us turn your vision into reality!</p>
			</div>
			<div className="reviwe_wrapper">
				<div className="reviwe_cnt">
					<div className="r_img">
						<img src={r1} alt=""/>
					</div>
					<div className="r_text">
						<h3>
							Emma Williams
						</h3>
						<img src={star} alt=""/>
						<p>Our corporate event was flawlessly executed! The team managed every detail, from the décor to the entertainment. It was an amazing experience for all of us, and we're still talking about it weeks later.</p>
					</div>
				</div>
				<div className="reviwe_cnt">
					<div className="r_img">
						<img src={r2} alt=""/>
					</div>
					<div className="r_text">
						<h3>James Harris</h3>
						<img src={star} alt=""/>
						<p>I had my birthday party planned by this team, and it was an unforgettable evening! Their attention to detail was outstanding, and the atmosphere they created was absolutely spot on. I highly recommend their services!</p>
					</div>
				</div>
				<div className="reviwe_cnt">
					<div className="r_img">
						<img src={r3} alt=""/>
					</div>
					<div className="r_text">
						<h3>Sophie Thompson</h3>
						<img src={star} alt=""/>
						<p>We organized a private Horse Racing Night, and it was a tremendous success! Everyone had a fantastic time, and the event was impeccably arranged. It felt just like a professional race held right at our venue. Outstanding work!</p>
					</div>
				</div>
			</div>
		</div>
	</section>

	

	<section className="faq_area">
		<div className="container">
			<div className="faq_title">
				<div className="events_title">
					<h2>Find Answers to Common Questions About Our Event Planning Services</h2>
					<p>Explore our FAQ section to get quick answers about how we organize unforgettable, offline events tailored just for you.</p>
				</div>
			</div>
			<div className="faq-container">
				<div className="faq-item">
					<details>
						<summary>
							How do you tailor events for each client?
						</summary>
						<p>We believe that every event should showcase the unique style and vision of our clients. When you reach out to us, we initiate the process with a thorough conversation to understand your preferences, goals, and expectations. We gather information about the type of event you’re planning, the atmosphere you wish to create, the number of guests, and any specific themes or ideas you have in mind. From that point, our creative team collaborates with you to craft a customized event concept, ensuring that every detail—from décor and entertainment to the overall flow—aligns with your vision. Whether you’re aiming for a polished corporate event, a lively birthday bash, or a themed night like Horse Racing, we utilize our expertise to make your event truly exceptional.</p>
					</details>
				</div>

				<div className="faq-item">
					<details>
						<summary>
							What kinds of events do you focus on?
						</summary>
						<p>We specialize in organizing a diverse range of offline events that connect people in unique and unforgettable ways. Our expertise spans from intimate private celebrations like birthdays and anniversaries to larger, more structured events such as corporate parties and themed nights. One of our highlight offerings is themed event nights, like Horse Racing, where we create an immersive experience that captures the excitement and energy of live events. We also excel at planning exclusive private gatherings, emphasizing a highly personalized and bespoke experience. No matter the size or type of event, we handle all aspects—from planning and logistics to décor and entertainment—ensuring a seamless experience for you and your guests.</p>
					</details>
				</div>

				<div className="faq-item">
					<details>
						<summary>
							What distinguishes your event planning services from others?
						</summary>
						<p>
							What sets us apart is our steadfast commitment to personalization and offline event experiences. Unlike other event organizers that may provide one-size-fits-all solutions, we take the time to understand each client’s unique desires and preferences. Our team goes beyond merely offering a venue and entertainment—we carefully curate every aspect to align with your vision. Whether it’s a birthday party with customized themes or a private event featuring tailored entertainment, we ensure that each event is unique. Additionally, all our events are completely offline, emphasizing in-person interactions and fostering genuine connections among your guests. Our meticulous attention to detail, creativity, and dedication to quality make us the preferred choice for those seeking to host truly memorable and immersive events.
						</p>
					</details>
				</div>

		

			</div>
		</div>
	</section>

	<section id="contact">
		<div className="GcXGI9"><div className="NnEaZe"><div className="d2GFdA"><h1 className="reYbGw pSnrE0">Get in Touch with Us<span className="qUtLBp"></span></h1><p className="aEW9qr q6oMlS">Have questions or ready to plan your next unforgettable event? We’re here to help!</p></div><form className="vewoo8"><div><input id="firstname" type="text" name="firstname" placeholder="FirstName" className="KBq8g4" value=""/></div><div><input id="surname" type="text" name="surname" placeholder="LastName" className="KBq8g4" value=""/></div><div><input id="email" type="email" name="email" placeholder="Email" className="KBq8g4" value=""/></div><div><input name="phone" placeholder="Phone Number" className="KBq8g4" value=""/></div><div><input id="event_type" type="text" name="event_type" placeholder="Event Type" className="KBq8g4" value=""/></div><div><input id="event_date" type="date" name="event_date" placeholder="Event Date" className="KBq8g4" value=""/></div><div><textarea id="comment" name="comment" placeholder="Comment" className="hA1zbd"></textarea></div><button type="submit" className="TMB4Tn btnmax"><span>Contact Us Today!</span></button></form><div className="Toastify"></div></div></div>
	</section>
    </div>
  )
}

export default Main
