import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer className="footer_area">
      <div className="container">
        <div className="f_inner">
          <div className="f_logo">
            <Link to="/">
              <img src="~/assets/images/event_4_logo.png" alt="" />
            </Link>
          </div>
          <div className="f_nav">
            <ul>
              <li>
                <Link to="/terms">
                  Terms of service
                </Link>
              </li>
              <li>
                <Link to="/cookie">
                  Cookie Policy
                </Link>
              </li>
              <li>
                <Link to="/privacypolicy">
                  Privacy policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
