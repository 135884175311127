import React from 'react'
import '../../components/blackView/Black.css';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom'
import HeaderBlack from './HeaderBlack';
import FooterBlack from './FooterBlack';
import favicon from '../blackView/images/icons/casino.png';

function BlackLayout() {
    return (
        <div id='blackLayout'>
                 <Helmet>
                <title>Best Online Casinos Australia 2024</title>
                <meta name="description" content="Best Online Casinos Australia 2024" />
                <link rel="icon" type="image/x-icon" href={favicon} />
            </Helmet>
      <HeaderBlack/>
            <Outlet />
            <FooterBlack/>
        </div>
    )
}

export default BlackLayout
